import * as React from "react";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import { Grid, Divider, ListItemText, Switch, FormGroup, FormControlLabel, Checkbox } from "@mui/material";
import { usePallet } from "../../../contexts/PalletContext";
import InputDatePicker from "../../../components/InputDatePicker";
import InputMultilineTextField from "../../../components/InputMultilineTextField";
import AnexosComponent, { EAnexoModulos } from "../../../components/Anexos";
import SolidButton from "../../../components/SolidButton";
import { isMobile } from "react-device-detect";
import EditIcon from "@mui/icons-material/Edit";
import ProposicaoAcontecimentosService from "../../../services/ProposicaoAcontecimentosService";
import { useAlert } from "../../../contexts/AlertContext";
import RoomIcon from "@mui/icons-material/Room";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import InputTextField from "../../../components/InputTextField";
import AccessAlarmIcon from "@mui/icons-material/AccessAlarm";
import { formatDate } from "../../../utils/dateUtils";
import { format } from "date-fns";
import ButtonIconSalvar from "../../../components/ButtonIconSalvar";
import ButtonDialogConfirmation from "../../../components/ButtonDialogConfirmation";
import * as Yup from "yup";
import CitacoesService from "../../../services/CitacoesService";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import ImageLoader from "../../../components/ImageLoader";
import SelectTextFieldA from "../../../components/SelectTextFieldA";
import UsuarioService from "../../../services/UsuarioService";
import TabelasService from "../../../services/TabelasService";
import GerUsuarioCadastroCreateSchema from "./GerUsuarioCadastroCreateSchema";
import { useAuthorization } from "../../../contexts/AuthorizationContext";


const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

export interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose: () => void;
}

const BootstrapDialogTitle = (props: DialogTitleProps) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

export default function CustomizedDialogs(props: {
  onChange?:() => void
  reloadList?: () => void;
  closeMenu?: () => void;
  idPermissao?: number;
}) {
  const { user, getConfig } = useAuthorization();
  const { NewAlert } = useAlert();
  const [open, setOpen] = React.useState(false);
  const [status, setStatus] = React.useState(true);
  const { pallet } = usePallet();
  const [dateAcontecimentos, setDateAcontecimentos] = React.useState();
  const [dataAcontecimentosAgenda, setDataAcontecimentosAgenda] =
    React.useState();
  const [nome, setNome] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [usuario, setUsuario] = React.useState("");
  const [senha, setSenha] = React.useState("");
  const [telefone, setTelefone] = React.useState("");
  const [permissao, setPermissao] = React.useState<number>(undefined);
  const [departamento, setDepartamento] = React.useState<number>(undefined)
  const [listaDepartamento, setListaDepartamento] = React.useState([]);
  const [confirmacaoEmail, setConfirmacaoEmail] = React.useState<boolean>(false);
  const [confirmacaoAlerta, setConfirmacaoAlerta] = React.useState<boolean>(false);
  const [confirmacaoWhats, setConfirmacaoWhats] = React.useState<boolean>(false);
  const [listaPermissao] = React.useState([
    {
      key: 1,
      label: "Master",
      value: 1
    },
    {
      key: 2,
      label: "Normal",
      value: 2
    },
    {
      key: 3,
      label: "Read Only",
      value: 3
    },
    {
      key: 4,
      label: "Esforço Colaborativo",
      value: 4
    }

  ]);
  const [dataExpiracao, setDataExpiracao] = React.useState<any>();
  const [onSave, setOnSave] = React.useState(false);
  const [idRef, setIdRef] = React.useState();
  const [idAgRef, setIdAgRef] = React.useState<string>();
  const [data, setData] = React.useState({});
  const [horas, setHoras] = React.useState<string>();
  const [local, setLocal] = React.useState<string>();
  const [error, setError] = React.useState<
    Array<{ name: string; err: string }>
  >([]);
  const [imageGet, setImageGet] = React.useState("");
  const [imageSelected, setImageSelected] = React.useState({});

  const handleDataAcontecimentosAgendaChange = (dataAcEve) => {
    setDataAcontecimentosAgenda(dataAcEve);
  };
  React.useEffect(() => {
    TabelasService.getTabelaDepartamentos().then(async (res) => {
      const data = [];
      data.push({ key: "", label: "-- Selecione -- ", value: "" });
      res?.map((item) =>
        data.push({
          key: item.descricao,
          label: item.descricao,
          value: Number(item.id),
        })
      );
      setListaDepartamento(() => [...data]);
    });
  }, []);

  const handleDepartamentoOnChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setDepartamento(Number(event.target.value));
  };
  const handlePermissaoOnChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setPermissao(Number(event.target.value));
  };
  const handleTelefoneOnChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setTelefone(event.target.value);
  };

  const   handleOnChangeStatus = (

  ) => {
    setStatus(true);
  };


  const handleConfirmacaoEmailChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const value = !!event.target.checked;
    setConfirmacaoEmail(value);
  };

  const handleConfirmacaoAlertaChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const value = !!event.target.checked;
    setConfirmacaoAlerta(value);
  };

  const handleConfirmacaoWhatsChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const value = !!event.target.checked;
    setConfirmacaoWhats(value);
  };



  const handleClickOpen = async () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const onImageSelect = (file) => {
    setImageSelected(file);
  };
  const handleChangeNome = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNome(event.target.value);
  };
  const handleChangeEmail = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value);
  };
  const handleChangeUsuario = (event: React.ChangeEvent<HTMLInputElement>) => {
    setUsuario(event.target.value);
  };
  const handleChangeSenha = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSenha(event.target.value);
  };

  const KeywordSchema = 

  Yup.object({
    nome: Yup.string().required({ name: "nome", err: "Campo com preenchimento obrigatório" }),
    telefone: Yup.string().required({ name: "telefone1", err: "Campo com preenchimento obrigatório" })
    .matches(
        /^[a-zA-Z0-9@]+$/,
        "Não é permitido espaços em branco neste campo."),
      id_departamento: Yup.number().required({ name: "departamento", err: "Campo com preenchimento obrigatório" }),
  email: Yup.string().required({ name: "email", err: "Campo com preenchimento obrigatório" }),
  usuario: Yup.string().required({ name: "usuario", err: "Campo com preenchimento obrigatório" }),
  id_permissao: Yup.number().required({ name: "permissao", err: "Campo com preenchimento obrigatório" })

});







  const postCadastro = async (e) => {
    e.preventDefault();
    const data = {
      nome: nome,
      email :email,
      telefone: telefone,
      usuario: usuario,
      password: senha,
      id_permissao: permissao,
      id_departamento: departamento,
      imagem: imageSelected,
      dataExpiracao: dataExpiracao,
      confirmacaoEmail: confirmacaoEmail,
      confirmacaoWhats: confirmacaoWhats,
      confirmacaoAlerta : confirmacaoAlerta


    };



    // data &&
    //   KeywordSchema.validate(data, { abortEarly: false }).catch((err) =>
    //     setError(() => err.errors)
    //   );
    setData(data);
    setError(() => [])
    data && KeywordSchema
        .validate(data, { abortEarly: false })
        // .catch(err => setError(() => err.errors.replace('Não é permitido espaços em branco neste campo.',{ name: "telefone", err: 'Não é permitido espaços em branco neste campo.' })));
        // .catch(err =>setError(() => err.errors.find((i) => i = 'Não é permitido espaços em branco neste campo.')) );
        .catch(err => {
          err.errors[err.errors.findIndex((i) => !i.name)] = {name:'telefone',err:'Não é permitido espaços em branco neste campo.'}
          setError(err.errors)
        }
        );
        
        
    setData(data)
    KeywordSchema.validate(data).catch(function (err) {
    });
      console.log('data: - - - -', error)
      if (KeywordSchema.isValidSync(data)) {
      await UsuarioService.CreateGer(data).then((res) => {
        if (res && res > 1) {
          NewAlert("success", "Usuário cadastrado com sucesso.");
          setNome("");
          setEmail("");
          setUsuario("");
          setTelefone("");
          setSenha("");
          setPermissao(undefined);
          setDepartamento(undefined);
          props.onChange();
          handleClose();
        } else if(res == -1){
          NewAlert(
            "error",
            "Erro ao cadastrar, já existe um usuário com este telefone."
          );


        }else {
          NewAlert(
            "error",
            "Erro ao cadastrar, verifique os campos e salve novamente."
          );
        }
      });

    }
  };

  const handleDataExpiracaoChange = (prazo) => {
    setDataExpiracao(prazo)
};

  async function handleOnAfterUpload() {
    if (idRef) {
      setIdRef(undefined);
      setOnSave(false);
    }
  }



  const ErrorMessage = (props: {
    name: string;
    errors: Array<{ name: string; err: string }>;
   
  }) => {
    console.log("teste no erro",props.errors)
    console.log("PERMISSAO",props.errors.length)
    const itemError = props.errors.filter((err) => err.name === props.name);

    return  (
      

    <div style={{ color: 'red', fontSize: '11px', marginTop: "5px", }}>
      {itemError.length === 1 && itemError[0].err}
    </div>)};


  return (
    <div>
      <ButtonIconSalvar
        onClick={() => props.idPermissao !== 3 ? handleClickOpen() : NewAlert(
          "error",
          "Não é possível realizar está ação com este perfil.")}
        tipo={"cadastrar_usuario"}
        backgroundColor={pallet.backgroundColorPrimary}
        border={"1px solid lightgrey"}
        fontColor={pallet.textColorPrimary}
        fontSize={pallet.general.size12}
        icon={<PersonAddIcon />}
        title={"CADASTRAR"}
      />

      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        fullWidth
        maxWidth="lg"
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleClose}
        >
          <Grid
            style={{
              fontFamily: `${pallet.fontFamily}`,
              fontSize: `${pallet.general.size18}`,
              color: `${pallet.textColorSecondary}`,
              padding: "12px",
              marginTop: "7px",
            }}
          >
            CADASTRAR USUÁRIO
          </Grid>
        </BootstrapDialogTitle>

        <DialogContent dividers style={{ padding: "0px" }}>
          <Grid
            container
            style={{
              fontFamily: pallet.fontFamily.general,
              padding: "25px 20px 20px 40px",
            }}
          >
            FOTO DE PERFIL
            <Grid container alignItems="center" direction="row">
              <Grid item>
                <ImageLoader
                  src={imageGet}
                  pallet={pallet}
                  canEdit={true}
                  onLoad={onImageSelect}
                />
              </Grid>
              <Grid
                item
                style={{
                  marginLeft: `${isMobile ? "0px" : "40px"}`,
                  marginTop: `${isMobile ? "10px" : "0px"}`,
                }}
              >
                <Grid container>
                  <Grid
                    item
                    style={{
                      fontSize: `${pallet.general.size12}`,
                      fontWeight: pallet.general.weight_600,
                      color: `${pallet.textColorSecondary}`,
                    }}
                  >
                    Tamanho máximo:
                  </Grid>
                  <Grid
                    item
                    style={{
                      fontSize: `${pallet.general.size12}`,
                      fontWeight: pallet.general.weight_100,
                      color: `${pallet.textColorSecondary}`,
                      marginLeft: "3px",
                    }}
                  >
                    2MB
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid
                    item
                    style={{
                      fontSize: `${pallet.general.size12}`,
                      fontWeight: pallet.general.weight_600,
                      color: `${pallet.textColorSecondary}`,
                    }}
                  >
                    Formatos Suportados:
                  </Grid>
                  <Grid
                    item
                    style={{
                      fontSize: `${pallet.general.size12}`,
                      fontWeight: pallet.general.weight_100,
                      color: `${pallet.textColorSecondary}`,
                      marginLeft: "3px",
                    }}
                  >
                    JPG, PNG
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid
              style={{ marginTop: "20px", padding: "10px" }}
              item
              lg={6}
              xs={12}
            >
              <InputTextField
                type={"text"}
                value={nome}
                name="nome"
                onChange={handleChangeNome}
                id={"nome-completo"}
                label={"NOME COMPLETO"}
              />
                <Grid style={{ color: 'red', fontSize: '10px', marginTop: "2px", marginLeft: "2px" }}>
                  <ErrorMessage name='nome' errors={error} />
                </Grid>
            </Grid>
            <Grid
              style={{ marginTop: "20px", padding: "10px" }}
              item
              lg={6}
              xs={12}
            >
              <InputTextField
                type={"telefone"}
                value={telefone}
                name="telefone"
                onChange={handleTelefoneOnChange}
                id={"telefone"}
                label={"TELEFONE"}
                maxLenght={12}
              />
               <Grid style={{ color: 'red', fontSize: '10px', marginTop: "2px", marginLeft: "2px" }}>
              <ErrorMessage name='telefone1' errors={error} />
              </Grid>
              <Grid style={{ color: 'red', fontSize: '10px', marginTop: "2px", marginLeft: "2px" }}>
              <ErrorMessage name='telefone' errors={error} />
              </Grid>
            </Grid>
            <Grid
              style={{ marginTop: "20px", padding: "10px" }}
              item
              lg={6}
              xs={12}
            >
              <SelectTextFieldA
                name={"departamento"}
                value={departamento}
                onChange={handleDepartamentoOnChange}
                options={listaDepartamento}
                label={"UNIDADE DE NEGÓCIO"}
                id={"departamento"}
              />
              <Grid style={{ color: 'red', fontSize: '10px', marginTop: "2px", marginLeft: "2px" }}>
              <ErrorMessage name='id_departamento' errors={error} />
              </Grid>
            </Grid>
            {user.id_permissao === 1 ? 
            <Grid
              style={{ marginTop: "20px", padding: "10px" }}
              item
              lg={6}
              xs={12}
            >
            <InputDatePicker
            onChange={handleDataExpiracaoChange}
            label={"DATA DE EXPIRAÇÃO"}
            inputFormat={"dd/MM/yyyy"}
            defaultValue={null}
            value={dataExpiracao}
            id={"data-expiracao"}
            minDate={new Date}
        />
        </Grid>
        :
        null
        }

            <Grid
              style={{ marginTop: "20px", padding: "10px" }}
              item
              lg={12}
              xs={12}
            >
              <InputTextField
                type={"email"}
                value={email}
                name="email"
                onChange={handleChangeEmail}
                id={"email"}
                label={"EMAIL"}
              />
              <Grid style={{ color: 'red', fontSize: '10px', marginTop: "2px", marginLeft: "2px" }}>
              <ErrorMessage name='email' errors={error} />
              </Grid>
            </Grid>
            <Grid
              style={{ marginTop: "20px", padding: "10px" }}
              item
              lg={6}
              xs={12}
            >
              <InputTextField
                type={"text"}
                value={usuario}
                name="usuario"
                onChange={handleChangeUsuario}
                id={"usuario"}
                label={"USUÁRIO"}
              />
                <Grid style={{ color: 'red', fontSize: '10px', marginTop: "2px", marginLeft: "2px" }}>
                  <ErrorMessage name='usuario' errors={error} />
                </Grid>
            </Grid>
            <Grid
              style={{ marginTop: "20px", padding: "10px" }}
              item
              lg={6}
              xs={12}
            >
              <SelectTextFieldA
                value={permissao}
                onChange={handlePermissaoOnChange}
                options={listaPermissao}
                label={"PERMISSÃO"}
                id={"permissao"}
              />
                <Grid style={{ color: 'red', fontSize: '10px', marginTop: "2px", marginLeft: "2px" }}>

                  <ErrorMessage name='id_permissao' errors={error} />
                  </Grid>
            </Grid>
            <Grid
              style={{ marginTop: "20px"}}
              item
              lg={12}
              xs={12}
            >
              Notificações
            </Grid>

             <Grid
              style={{ marginTop: "5px", padding: "10px" }}
              item
              lg={12}
              xs={12}
            >
                      
              {getConfig()?.habNotEmail ?  
   
                      <FormGroup
                        style={{ fontFamily: `${pallet.fontFamily.general}` }}
                      >
                        <FormControlLabel
                          control={
                            <Checkbox
                              defaultChecked={confirmacaoEmail}
                              value={confirmacaoEmail}
                              onChange={handleConfirmacaoEmailChange}
                              style={{
                                color: `${pallet.color.secundary.font}`,
                              }}
                            />
                          }
                          label="Receber por email."
                        />
                      </FormGroup>
                      :
                      null
                           }
                      {getConfig()?.habNotPush ?  
                      <FormGroup
                        style={{ fontFamily: `${pallet.fontFamily.general}` }}
                      >
                        <FormControlLabel
                          control={
                            <Checkbox
                              defaultChecked={confirmacaoAlerta}
                              value={confirmacaoAlerta}
                              onChange={handleConfirmacaoAlertaChange}
                              style={{
                                color: `${pallet.color.secundary.font}`,
                              }}
                            />
                          }
                          label="Receber por alerta do portal(navegador de internet)."
                        />
                      </FormGroup>
                      :
                      null
                      }
                      {getConfig()?.habNotWhat ?  
                      <FormGroup
                        style={{ fontFamily: `${pallet.fontFamily.general}` }}
                      >
                        <FormControlLabel
                          control={
                            <Checkbox
                              defaultChecked={confirmacaoWhats}
                              value={confirmacaoWhats}
                              onChange={handleConfirmacaoWhatsChange}
                              style={{
                                color: `${pallet.color.secundary.font}`,
                              }}
                            />
                          }
                          label="Receber por WhatsApp."
                        />
                      </FormGroup>
                      :
                      null
                      }

            </Grid>

            <Grid
              style={{
                color: "red",
                fontSize: "10px",
                marginTop: "2px",
                marginLeft: "2px",
              }}
            >
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions
          style={{
            padding: "30px",
            backgroundColor: `${pallet.backGroundPageColorPrimary}`,
          }}
        >
          <ButtonDialogConfirmation
            styles={{
              borderRadius: "6px 6px 0px 0px",
              marginRight: "20px",
              textTransform: "capitalize",
              color: `${pallet.general.color1}`,
            }}
            title={"CONFIRMAÇÃO"}
            title_button={"Cancelar"}
            content={"CANCELAR CADASTRO?"}
            message={"Esta ação não poderá ser desfeita."}
            confirmation_button={"Cancelar"}
            confirmationEvent={() => {
              handleClose();
            }}
          />
          <SolidButton
            onClick={postCadastro}
            onChange={props?.reloadList}
            color={`${pallet.textColorPrimary}`}
            backgroundColor={`${pallet.backgroundColorPrimary}`}
            fontSize={`${pallet.general.size14}`}
            title={"SALVAR"}
          />
        </DialogActions>
      </BootstrapDialog>
    </div>
  );
}
