
import React from "react";
import { isMobile } from "react-device-detect";

import { IconButton, Grid, FormControlLabel } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';

import RestoreFromTrashIcon from '@mui/icons-material/RestoreFromTrash';
import AssignmentIcon from "@mui/icons-material/Assignment";
import AccountBoxIcon from "@mui/icons-material/AccountBox";
import InventoryIcon from '@mui/icons-material/Inventory';
import EventIcon from '@mui/icons-material/Event';
import LinkIcon from '@mui/icons-material/Link';
import XIcon from '@mui/icons-material/Close';
import TryIcon from '@mui/icons-material/Try';
import ComJabuti from "../../../img/michaelangelo_com_jabuti.png";
import styled from 'styled-components';

import { useAlert } from "../../../contexts/AlertContext";
import { usePallet } from '../../../contexts/PalletContext';

import NotificationsContextService, { INotificationsContextResultData } from '../../../services/NotificationsContextService';
import ProposicaoService from "../../../services/ProposicaoService";
import TabelasService from "../../../services/TabelasService";

import { formatDate } from '../../../utils/dateUtils';

import NotificacaoMenuButton from './NotificacaoMenuButton';

import ButtonSeguir from "../../../components/ButtonSeguir";
import ButtonNaoSeguir from "../../../components/ButtonNaoSeguir";
import FavoritosService from "../../../services/FavoritosService";
import StarIcon from '@mui/icons-material/Star';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import maqui_head from './../../../img/maqui_head.png';


const NotificacaoNotContent = styled(Grid)`
padding: 14px;
a {
    text-decoration: none;
    color: #4B1196;
}
    
span {
    color: #4B1196;
    font-weight: 600;
}
    
b {
    color: #1E0736
}

ul {
    padding-left: 0px;
    li {
        color: #1E0736;
        font-weight: 400;
        word-break: break-word;
        list-style-type: '';

        >b::before{
            content: '»';
        }
        /* padding-inline-start: 0.7ch; */
        /* color: #1E0736; */

        &::marker {
            /* font-weight: bold; */
        }
    }
}
`


const NotificacaoNotFooter = styled(Grid)`
padding: 0 14px 14px 14px;
a {
    color: #1E0736;
    display: flex;
    text-decoration: unset;
}
svg {
    color: #1E0736;
    margin-right: 10px
}`


const getIcon = (modulo: string, cor: string) => {
    switch (modulo) {
        case 'PROPOSIÇÃO':
            return <AssignmentIcon style={{ color: cor }} />
        case 'INTERLOCUTOR':
            return <AccountBoxIcon style={{ color: cor }} />
        case 'AGENDA':
            return <EventIcon style={{ color: cor }} />
        case 'CITAÇÕES':
            return <TryIcon style={{ color: cor }} />
        case 'TWITTER':
            return <XIcon style={{ color: cor }} />
    };
};

export interface IDeleted {
    deletado: boolean
};


const NotificacaoCard = (props: {
    notification?: INotificationsContextResultData,
    central?: boolean,
    checked?: boolean,
    onDelete?: (event: any) => void,
    onChange?: (event: any) => void,
    limpar?: boolean,
    index?: number,
    arquivado?: boolean,
    status?: string,
    reloadList?: () => void,
    idsFlow?: (event: any, ids: number) => void,
    arquivou?: string,
    idPermissao?: number,
    filtro?: string
    removeNotificacao?: (id: number) => void
}) => {
    const { pallet } = usePallet();
    const { NewAlert } = useAlert();
    const [checked, setChecked] = React.useState(false);
    const [seguir, setSeguir] = React.useState(false);
    const [favoritado, setFavoritado] = React.useState<boolean>(false);
    const [idFavorito, setIdFavorito] = React.useState(0);

    const handleChange = (event?: any, index?: any, id?: number) => {
        props.idsFlow(event, id);
        setChecked(event);
    };

    React.useEffect(() => {
        if (props.limpar === true) {
            setChecked(false);
        };
    }, [props.checked, props.limpar]);

    const handleArquivar = () => {
        if (!props.central) {
            props.removeNotificacao(props.notification.id);
        };

        NotificationsContextService.Arquivar(props.notification.id).then((res) => {
            if (res) {
                NewAlert("success", "Notificação arquivada.");
                props.reloadList();
            } else {
                NewAlert("error", "Houve um erro. Tente novamente.");
            };
        });
    };

    const handleDesarquivar = () => {
        NotificationsContextService.Desarquivar(props.notification.id).then((res) => {
            if (res) {
                NewAlert("success", "Notificação desarquivada.");
                props.reloadList();
            } else {
                NewAlert("error", "Houve um erro. Tente novamente.");
            };
        });
    };

    const checandoProp = (id: number) => {
        TabelasService.getProposicaoDeleted(Number(id)).then(proposicao => {
            if (proposicao !== undefined) {
                if (proposicao[0]?.deletado) {
                    NewAlert("error", "Proposição excluida")
                } else {
                    window.open(props.notification?.path, "_blank")
                };
            } else {
                window.open(props.notification?.path, "_blank")
            };
        });
    };

    const tratarId = (caminho: string) => {
        return caminho.substring(caminho.lastIndexOf('/') + 1, caminho.length);
    };

    const permissaoErro = () => {
        NewAlert("error", "Não é possível realizar está ação com este perfil.");
    };

    const seguirClick = (id: number, value: boolean, path: string = undefined) => {
        ProposicaoService.SetSeguir(id, value, path).then(
            res => {
                if (res) {
                    setSeguir(value);
                    props.reloadList();
                    NewAlert('success', 'Seguiu com sucesso');
                    if (!value) {
                        handleArquivar();
                    };
                } else {
                    NewAlert('error', 'Ocorreu um erro');
                };

            }
        );
    };

    const getCorTitleBackground = (tipo: number) => {
        switch (tipo) {
            case 100:
                return '#C0C0C0';
            case 103:
                return '#1E90FF';
            case 112:
                return '#7FFFD4';
            case 200:
                return '#DAA520';
            case 202:
                return '#F5DEB3';
            case 300:
                return '#90EE90';
            case 406:
                return '#F08080';
            default:
                return '#DED3FF';
        };
    };

    const getCorIcon = (tipo: number) => {
        switch (tipo) {
            case 100:
                return '#808080';
            case 103:
                return '#191970';
            case 112:
                return '#008080';
            case 200:
                return '#B8860B';
            case 202:
                return '#DEB887';
            case 300:
                return '#006400';
            case 406:
                return '#800000';
            default:
                return '#4B1196';
        };
    };

    React.useEffect(() => {
        if (props.notification.tipo === 100) {
            FavoritosService.getFavoritoUnico(Number(props.notification.referencia), 'proposicao').then((res) => {
                if (res) {
                    setIdFavorito(res.id)
                    setFavoritado(true)

                } else {
                    setFavoritado(false)
                }
            });
        }
    }, []);

    const handleChangeFavorito = async (event: any, id: number, modulo: string,) => {
        const data = {
            modulo: modulo,
            id_modulo: id,
            tipo: 'PROPOSIÇÕES'
        };

        FavoritosService.getFavoritoUnico(props.notification.referencia, 'proposicao').then((res) => {
            if (res.id) {
                setFavoritado(false)
                FavoritosService.Delete(id, res.id, false, 'PROPOSIÇÕES').then((res) => {
                    if (res) {
                        NewAlert("success", "O item foi removido dos favoritos com sucesso.");
                    } else {
                        NewAlert(
                            "error",
                            "Erro ao Deletar, tente novamente."
                        );
                    }
                });
            } else {
                FavoritosService.Create(data).then((res) => {
                    if (res) {
                        NewAlert("success", "O item foi adicionado aos favoritos com sucesso.");
                    }
                    else {
                        NewAlert("error", "Erro ao favoritar, verifique os campos e salve novamente.");
                    }
                });
            }
        });
    }

    return (
        <Grid key={props.notification?.referencia} style={{ alignItems:isMobile? null : 'center', border: '1px solid #EAEAEA', margin: '10px 0' }} >
            <div style={{
                display: 'flex',
                flexWrap: 'nowrap',
                flexDirection: 'row',
                width: '100%',
                height: isMobile ? '170px' : '100%',
                borderBottom: '1px solid #EAEAEA',
                backgroundColor: '#F9F9F9',
            }} >

                <div style={{
                    backgroundColor: getCorTitleBackground(props.notification.tipo),
                    width: '50px',
                    height: '58px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center'
                }}>
                    {props?.notification.tipo === 119 ? <img
                        src={ComJabuti}
                        alt="Icone"
                        style={{
                            width: "40px",
                            height: "40px",

                        }}
                    /> : getIcon(props?.notification?.modulo.toUpperCase(), getCorIcon(props.notification.tipo))}
                </div>

                <div style={{
                    display: 'flex',
                    flexWrap: 'nowrap',
                    fontSize: '12px',
                    width: '100%',
                    fontWeight: pallet.general.weight_600,
                    textTransform: 'uppercase',
                    color: '#1E0736',
                    margin: '5px 10px'
                }}>
                    {props.notification.tipo === 303 || props.notification.tipo === 304 ?
                        <div style={{ width: isMobile ? '50px' : '300px', height: '30px' }}>
                            <span style={{ display: 'block', color: '#1E0736' }}>{props?.notification?.descricao}</span>
                            {isMobile ?
                                <Grid style={{display: 'inline-block'}}>
                                <span style={{ color: '#1E0736', fontWeight: pallet.general.weight_500 }}>{props?.notification?.data_evento ? formatDate(new Date(props.notification.data_evento), "dd/MM/yyyy") : "Data indefinida"}</span>
                                <span style={{ color: '#00000091', fontWeight: pallet.general.weight_500 }}>{props?.notification?.criado_em && ` (notificado em ${formatDate(new Date(props.notification.criado_em), "dd/MM/yyyy")})` || ""}</span>
                                </Grid>  

                                :
                                <p>
                                <span style={{ color: '#1E0736', fontWeight: pallet.general.weight_500 }}>{props?.notification?.data_evento ? formatDate(new Date(props.notification.data_evento), "dd/MM/yyyy") : "Data indefinida"}</span>
                                <span style={{ color: '#00000091', fontWeight: pallet.general.weight_500 }}>{props?.notification?.criado_em && ` (notificado em ${formatDate(new Date(props.notification.criado_em), "dd/MM/yyyy")})` || ""}</span>
                                </p>

                        
                        
                            }
                            <p>
                                <span style={{ color: '#1E0736', fontWeight: pallet.general.weight_500 }}>{props?.notification?.data_evento ? formatDate(new Date(props.notification.data_evento), "dd/MM/yyyy") : "Data indefinida"}</span>
                                <span style={{ color: '#00000091', fontWeight: pallet.general.weight_500 }}>{props?.notification?.criado_em && ` (notificado em ${formatDate(new Date(props.notification.criado_em), "dd/MM/yyyy")})` || ""}</span>
                            </p>
                        </div>

                        :

                        <div onClick={() => checandoProp(Number(tratarId(props.notification?.path)))} style={{ width: isMobile ? '50px' : '300px', height: '30px', cursor: "pointer" }}>
                            <span>{props?.notification?.descricao}</span>
                            {isMobile ?
                            <>
                                <span style={{ display: 'inline-block', color: '#1E0736', fontWeight: pallet.general.weight_500 }}>{props?.notification?.data_evento ? formatDate(new Date(props.notification.data_evento), "dd/MM/yyyy") : "Data indefinida"}</span>
                                <span style={{ color: '#00000091', fontWeight: pallet.general.weight_500 }}>{props?.notification?.criado_em && ` (notificado em ${formatDate(new Date(props.notification.criado_em), "dd/MM/yyyy")})` || ""}</span>
                            </>
                            :
                            <p>
                                <span style={{ display: 'inline-block', color: '#1E0736', fontWeight: pallet.general.weight_500 }}>{props?.notification?.data_evento ? formatDate(new Date(props.notification.data_evento), "dd/MM/yyyy") : "Data indefinida"}</span>
                                <span style={{ color: '#00000091', fontWeight: pallet.general.weight_500 }}>{props?.notification?.criado_em && ` (notificado em ${formatDate(new Date(props.notification.criado_em), "dd/MM/yyyy")})` || ""}</span>
                            </p>                          
                            }
                        </div>
                    }

                        {isMobile ? 
                    <div style={{marginLeft:'auto' }}>
                                                <Grid >
                                                {
                                                    props.notification.tipo === 100 && props.status === 'A' ?
                                                    <Grid style={{display: 'flex',
                                                        flexDirection: 'column' ,
                                                        alignItems: 'center',
                                                        justifyContent: 'center'}}>
                                                    <div>
                                                    <Checkbox
                                                    // {...label}
                                                    // icon={xc._source.favoritado === "nao_favorito" ? <StarBorderIcon /> : <StarIcon />}
                                                    icon={favoritado ? <StarIcon /> : <StarBorderIcon />}
                                                    checkedIcon={<StarIcon />}
                                                            defaultChecked={favoritado}
                                                            value={favoritado}
                                                            
                                                            style={{ color: `${pallet.general.color2}`,height:'3px' }}
                                                            onChange={(e) => handleChangeFavorito(e, props.notification.referencia, 'proposicao')}
                                                        /> 
                                                        </div>

                                                        <ButtonNaoSeguir
                                                            id={props.notification.referencia}
                                                            status={props.notification.seguindo}
                                                            onClick={() => seguirClick(props.notification.referencia, false, props.notification.path)}
                                                            width={isMobile ? "90px" :"140px"}
                                                        /> 

                                                        <ButtonSeguir
                                                            id={props.notification.referencia}
                                                            status={props.notification.seguindo}
                                                            onClick={() => seguirClick(props.notification.referencia, true, props.notification.path)}
                                                            width={isMobile ? "90px" : "110px"}
                                                            /> 
                                                            </Grid>
                                                            : null
                                                }
                                                { <div style={{marginTop: isMobile &&  props.notification.tipo == 117 ? "20px" : null}}></div>}
                                                {props.status === 'A' ?
                                                    <Checkbox key={props.index}
                                                        checked={checked}
                                                        onChange={(e) => props.idPermissao !== 3 ? handleChange(e.target.checked, props.index, props.notification.id) : NewAlert(
                                                            "error",
                                                            "Não é possível realizar está ação com este perfil.")}
                                                        inputProps={{ 'aria-label': 'controlled' }}
                                                        sx={{ maxHeight: '32px',
                                                          
                                                         }}
                                                    /> : null
                                                }
                    
                                                {props.central === true && props.status === 'ARQ' ?
                                                    <Checkbox key={props.index}
                                                        checked={checked}
                                                        onChange={(e) => handleChange(e.target.checked, props.index, props.notification.id)}
                                                        inputProps={{ 'aria-label': 'controlled' }}
                                                        sx={{ maxHeight: '32px',
                                                           
                                                         }}
                                                    /> : null
                                                }
                    
                                                <NotificacaoMenuButton notificacao={props.notification} reloadList={props.reloadList} filtro={props.central ? props.filtro : 'A'} />
                    
                                                {props.status === 'A' ?
                                                    <IconButton onClick={props.idPermissao !== 3 ? handleArquivar : permissaoErro}
                                                    >
                                                        <InventoryIcon style={{ color: pallet.general.color4 }} />
                                                    </IconButton> : null
                                                }
                    
                                                {props.status === 'ARQ' ?
                                                    <IconButton onClick={props.idPermissao !== 3 ? handleDesarquivar : permissaoErro}
                                                    >
                                                        <RestoreFromTrashIcon style={{ width: '26px', height: '26px', color: pallet.general.color4 }} />
                                                    </IconButton> : null
                                                }
                                            </Grid>
                                            </div>
                                            :
                                            <div style={{ marginLeft: 'auto'}}>
                                            <Grid container item alignItems={'baseline'}>
                                            {
                                                props.notification.tipo === 100 && props.status === 'A' ?
                                                    <Checkbox
                                                        // {...label}
                                                        // icon={xc._source.favoritado === "nao_favorito" ? <StarBorderIcon /> : <StarIcon />}
                                                        icon={favoritado ? <StarIcon /> : <StarBorderIcon />}
                                                        checkedIcon={<StarIcon />}
                                                        defaultChecked={favoritado}
                                                        value={favoritado}
                
                                                        style={{ color: `${pallet.general.color2}` }}
                                                        onChange={(e) => handleChangeFavorito(e, props.notification.referencia, 'proposicao')}
                                                    /> : null
                                            }
                
                                            {
                                                props.notification.tipo === 100 && props.status === 'A' ?
                                                    <ButtonNaoSeguir
                                                        id={props.notification.referencia}
                                                        status={props.notification.seguindo}
                                                        onClick={() => seguirClick(props.notification.referencia, false, props.notification.path)}
                                                        width={"140px"}
                                                    /> : null
                                            }
                
                                            {
                                                props.notification.tipo === 100 && props.status === 'A' ?
                                                    <ButtonSeguir
                                                        id={props.notification.referencia}
                                                        status={props.notification.seguindo}
                                                        onClick={() => seguirClick(props.notification.referencia, true, props.notification.path)}
                                                        width={"110px"}
                                                    /> : null
                                            }
                
                                            {props.status === 'A' ?
                                                <Checkbox key={props.index}
                                                    checked={checked}
                                                    onChange={(e) => props.idPermissao !== 3 ? handleChange(e.target.checked, props.index, props.notification.id) : NewAlert(
                                                        "error",
                                                        "Não é possível realizar está ação com este perfil.")}
                                                    inputProps={{ 'aria-label': 'controlled' }}
                                                    sx={{ maxHeight: '32px' }}
                                                /> : null
                                            }
                
                                            {props.central === true && props.status === 'ARQ' ?
                                                <Checkbox key={props.index}
                                                    checked={checked}
                                                    onChange={(e) => handleChange(e.target.checked, props.index, props.notification.id)}
                                                    inputProps={{ 'aria-label': 'controlled' }}
                                                    sx={{ maxHeight: '32px' }}
                                                /> : null
                                            }
                
                                            <NotificacaoMenuButton notificacao={props.notification} reloadList={props.reloadList} filtro={props.central ? props.filtro : 'A'} />
                
                                            {props.status === 'A' ?
                                                <IconButton onClick={props.idPermissao !== 3 ? handleArquivar : permissaoErro}
                                                >
                                                    <InventoryIcon style={{ color: pallet.general.color4 }} />
                                                </IconButton> : null
                                            }
                
                                            {props.status === 'ARQ' ?
                                                <IconButton onClick={props.idPermissao !== 3 ? handleDesarquivar : permissaoErro}
                                                >
                                                    <RestoreFromTrashIcon style={{ width: '26px', height: '26px', color: pallet.general.color4 }} />
                                                </IconButton> : null
                                            }
                                        </Grid>
                                   </div>
                        }

                </div>
            </div>

            <NotificacaoNotContent
                dangerouslySetInnerHTML={{
                    __html: props.notification?.mensagem ? props.notification?.mensagem : "<span>PLP230/2020 • <b>Apensada a</b> PL2267/2019</span>\n" +
                        "<b>Câmara dos Deputados</b>\n" +
                        "<ul>\n" +
                        "<li>\n" +
                        "<b> Ementa:</b> Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.\n" +
                        "</li>\n" +
                        "</ul>",
                }}
            >
            </NotificacaoNotContent>

            {/* <Grid
                item
                lg={12}
                xl={12}
                style={{
                    fontSize: pallet.general.size12,
                    margin: "-10px 20px 20px 20px",
                    paddingLeft: "30px",
                    background: 'linear-gradient(135deg, #3B0E6A, #6a0dad)',
                    color: '#fff',
                    padding: '20px',
                    borderRadius: '8px',
                    boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.2)',
                    transition: 'transform 0.2s',
                    display: 'flex',
                    alignItems: 'center'
                }}
            >
                <img
                    src={maqui_head}
                    alt="Descrição do GIF"
                    style={{
                        width: '60px',
                        height: '50px',
                        marginRight: '15px',
                    }}
                />
                <div>
                    <Grid
                        style={{
                            fontWeight: pallet.general.weight_500,
                            fontSize: '18px',
                            fontFamily: 'Montserrat'
                        }}
                    >
                        Hey! Tenho uma sugestão de classificação para você.
                    </Grid>
                    <Grid style={{ whiteSpace: "pre-wrap", fontWeight: pallet.general.weight_100 }}>
                        <Grid container style={{ marginTop: '5px', fontSize: '12px' }}>
                            <Grid>
                                (Clique aqui para visualizar)
                            </Grid>
                        </Grid>
                    </Grid>
                </div>
            </Grid> */}

            <NotificacaoNotFooter>
                {props.notification?.links && Array.isArray(props.notification?.links) && JSON.parse(props.notification?.links).map((item) => {
                    return <a href={item.url}><LinkIcon style={{ color: '#1E0736', marginRight: '10px' }} /> {item.descricao} </a>
                })}
            </NotificacaoNotFooter>
        </Grid >
    )
};

export default NotificacaoCard;
