import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Avatar,
    Box,
    capitalize,
    Checkbox,
    Divider,
    Grid,
    Paper,
    ToggleButton,
    ToggleButtonGroup,
    Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import useStyles from "../../components/layout/Styles";
import { usePallet } from "../../contexts/PalletContext";
import styled from "styled-components";
import Main from "../main/Main";
import filtersBackgroundImage from "../../../src/img/background_filters_dashboard.png";
import { isMobile } from "react-device-detect";
import CalendarOutlineIcon from "@mui/icons-material/CalendarTodayOutlined";
import DateRangeSelector from "../../components/DateRangeSelector";
import { format, subYears, parse } from "date-fns";
import ptBR from 'date-fns/locale/pt-BR';
import TiposList, { IFiltroTipoProps } from "../insights/_components/TiposLista";
import LineTitle from "./_components/LineTitle";
import { useAuthorization } from "../../contexts/AuthorizationContext";
import ComboBoxSemSelect from "../../components/ComboBoxSemSelect";
import { Link, useLocation, useParams } from 'react-router-dom';
import ProposicaoService from "../../services/ProposicaoService";
import StarIcon from '@mui/icons-material/Star';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import BookmarkBorderIcon from '@mui/icons-material/BookmarkBorder';
import BookmarkIcon from '@mui/icons-material/Bookmark';
import { useNavigate } from "react-router-dom";
import { IListInfo } from "../../models/IListInfo";
import PaginationSaveState from "../../components/PaginationSaveState";
import CircularLoading from '../../components/CircularLoading';
import UsuarioService from "../../services/UsuarioService";
import { useAlert } from "../../contexts/AlertContext";
import FavoritosService from "../../services/FavoritosService";
import ParlamentarService from "../../services/ParlamentarService";
import InsertLinkIcon from '@mui/icons-material/InsertLink';
import { BootstrapDialog, BootstrapDialogTitle } from "../../components/BootstrapDialogTitle";
import https from "https";
import moment from "moment-timezone";
import NewsService from "../../services/NewsService";
import FilterQuantity from "../../components/filter/FilterQuantity";
import { faGlasses } from "@fortawesome/free-solid-svg-icons";
import FavoriteIcon from '@mui/icons-material/Favorite';
import imgBackgroundImage from "../../../src/img/emptyImgBackground.png";
import EmptyError from '../../components/EmptyError';
import TwitterService from "../../services/TwitterService";
import TwitterIcon from '../../img/twitter_button.jpg';
import { formatDate } from "../../utils/dateUtils";
import ParlamentarCard from "../parlamentar/_components/ParlamentarCard";
import DiarioService from "../../services/DiarioService";
import DownloadIcon from '@mui/icons-material/Download';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import BuscaGeralDiarioModal from "./_components/Diario/BuscaGeralDiarioModal";
import { useQuery } from "../../utils/utils";
import UploadFilesService from "../../services/UploadFiles.service";
import { writeStore } from "../../helpers/persistence";



moment.tz.setDefault(process.env.TIMEZONE || 'America/Sao_Paulo');

const label = { inputProps: { 'aria-label': 'Checkbox demo' } };

const Root = styled('div')(() => ({
    width: '100%',
    '& > :not(style) + :not(style)': {
        marginTop: "10px",
        padding: '20px'
    },
}));

const ContainerFooter = styled(Grid)`
  margin-top: 40px;
  margin-bottom: 40px;
  justify-content: center;
`;

const TitleText = styled(Typography)`
      color: ${(props) => props.pallet.textColorQuaternary};
      font-weight: ${(props) => props.pallet.general.weight_bold};
      font-size: 24px;
      line-height: 29px;
      letter-spacing: 1px;
    `;

const TitleTextResult = styled(Typography)`
    color: ${(props) => props.pallet.textColorQuaternary};
    font-weight: ${(props) => props.pallet.general.weight_bold};
    font-size: 20px;
    line-height: 29px;
    letter-spacing: 1px;
    margin-left: 30px;
    `;

// declare global {
//     interface Window {
//         find: any;
//     }
// }
const PaperContent = styled(Paper)`
 color: ${(props) => props.pallet.textColorQuaternary};
 font-size: ${(props) => props.pallet.fontSize.titleWidgets};
 font-family: ${(props) => props.pallet.fontFamily.general};
 font-weight: 600;
 padding-top: 10%;
 padding-bottom: 10%;
 width: 100%;
 text-align: center;
 background-color: #ffff;
 heigth: 100%;
`;

const ContainerCard = styled(Grid)`
  margin-top: 20px;
  margin-left: 8px;
`;


const Geral = styled(Grid)`
div#content div {
    max-width: ${ isMobile ? `100% !important` : `null`};
}
`;
export default function BuscaGeral() {
    const NewsAPI = require('newsapi');
    const newsapi = new NewsAPI('c417d1626af4410f8db01cc324658cd4');
    const classes = useStyles();
    const { pallet } = usePallet();
    const [dtIni, setDtIni] = React.useState<Date>(subYears(new Date(), 1));
    const [dtFin, setDtFin] = React.useState<Date>(new Date());
    const [EsfSel, setEsfSel] = useState("Federal");
    const [itens, setItens] = React.useState<Array<any>>([]);
    const { user, getConfig } = useAuthorization();
    const { wordKey } = useParams() as any;
    const [statusFavorite, setStatusFavorite] = useState(false);
    const [infoList, setInfoList] = useState<IListInfo>({
        pageIndex: 1,
        pageSize: 8,
        pageItens: 0,
        totalItens: 0,
        totalPages: 0,
    });
    let filter = useQuery().get('var');

    let currentFilter = localStorage.getItem( 'filter' ) 
    // const[curretilter,setCurrentFilter] = useState("");
    const [currentPageSize, setCurrentPageSize] = useState<number>(50);
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [loading, setLoading] = useState<boolean>(false);
    const [data, setData] = React.useState({});
    const { NewAlert } = useAlert();
    const localArm = localStorage.getItem("filter");

    const [tipo, setTipo] = React.useState<Array<IFiltroTipoProps>>([
        { key: "proposicao", label: "PROPOSIÇÕES", active: filter === "proposicao"  ? true : currentFilter === "PROPOSIÇÕES" ? true : !filter && currentFilter === "PROPOSICAO" ? true : false },
        { key: "interlocutor", label: "INTERLOCUTORES", active: filter === "interlocutor" ? true : currentFilter === "INTERLOCURES" && !filter ? true : false },
        { key: "discurso", label: "DISCURSOS", active: filter === "discurso" ? true : currentFilter === "DISCURSOS" && !filter ? true : false, hide: getConfig()?.semElastic },
        { key: "news", label: "NOTÍCIAS", active: filter === "noticia" ? true : currentFilter === "NOTÍCIAS" && !filter ? true : false },
        { key: "twitter", label: "TWITTER", active: filter === "twitter" ? true : currentFilter === "TWITTER" && !filter ? true : false, hide: getConfig()?.semElastic },
        { key: "diario", label: "DOU", active: filter === "dou" ? true : currentFilter === "DOU"  && !filter ? true : false, hide: getConfig()?.semElastic || !getConfig()?.habDou },

    ]);
    const [, forceRender] = React.useState<number>();
    
    const [tipoAtual, SetTipoAtual] = React.useState(filter === "proposicao" ? "PROPOSIÇÕES" : filter === "interlocutor" ? "INTERLOCUTORES" : filter === "discurso" ? "DISCURSOS" : filter === "noticia" ? "NOTÍCIAS" : filter === "dou" ? "DIARIO" : filter === "twitter" ? "TWITTER" : currentFilter === "PROPOSIÇÕES" ? "PROPOSIÇÕES" : currentFilter === "INTERLOCUTORES" ?  "INTERLOCUTORES" : currentFilter === "DISCURSOS" ? "DISCURSOS" : currentFilter === "NOTÍCIAS" ? "NOTÍCIAS" : currentFilter === "TWITTER" ? "TWITTER" : currentFilter === "DOU" ? "DIÁRIO" : "PROPOSIÇÕES"  ) 
    const [starLoading, setStarLoading] = React.useState(false)
    const [selectedIndex, setSelectedIndex] = React.useState(null);
    const [openDialog, setOpenDialog] = React.useState();
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);


    const filterName = 'BuscaGeralProposicaoListCurrentPage';

    const navigate = useNavigate();

    const p = document.getElementById("p")
    


    // ----------- PROPOSICAO ------------------//
    // ----------- PROPOSICAO ------------------//
    // ----------- PROPOSICAO ------------------// 


    const loadListProposicao = async (origin?: String) => {
        console.log("ESTOU AQUI")
        // if (!origin) {
        setLoading(true)
        // }

        const dataInfoList = {
            pageIndex: currentPage,
            pageSize: currentPageSize,
        }
        currentPage && await ProposicaoService.GetElkGeralProposicao(wordKey, dataInfoList) // force break
            .then(res => {
                if (res) {
                    setItens(res.data);
                    setInfoList(res.listInfo);
                    setLoading(false)
                }
            });
    }

    const pressBuscarGeralProposicao = async () => {
        setLoading(true)
        const dataInfoList = {
            pageIndex: currentPage,
            pageSize: currentPageSize,
        }
        currentPage && await ProposicaoService.GetElkGeralProposicao(wordKey, dataInfoList) // force break
            .then(res => {
                if (res) {
                    setCurrentPage(1)
                    setItens(res.data)
                    setInfoList(res.listInfo)
                    setLoading(false)
                }
            });
    }




    const pressBuscarGeralDiario = async () => {

        setLoading(true)
        const dataInfoList = {
            pageIndex: currentPage  - 1,
            pageSize: currentPageSize,
        }
        currentPage && await DiarioService.GetElkGeralDiario(wordKey, dataInfoList) // force break
            .then(res => {

                if (res) {
                    setCurrentPage(1)
                    setItens(res.data)
                    setInfoList(res.listInfo)
                    setLoading(false)
                }
            });
    }


    const loadListDiario = async (origin?: String) => {
        // if (!origin) {
        setLoading(true)
        // }

        const dataInfoList = {
            pageIndex: currentPage  - 1,
            pageSize: currentPageSize,
        }
        currentPage && await DiarioService.GetElkGeralDiario(wordKey, dataInfoList) // force break
            .then(res => {
                if (res) {
                    setItens(res.data);
                    setInfoList(res.listInfo);
                    setLoading(false)
                }
            });
    }

    // ----------- INSTERLOCUTOR ------------------//
    // ----------- INSTERLOCUTOR ------------------//
    // ----------- INSTERLOCUTOR ------------------//


    const loadListInterlocutor = async (origin?: String) => {
        if (origin !== 'fav') {
        setLoading(true)
        };

        const dataInfoList = {
            pageIndex: currentPage,
            pageSize: currentPageSize,
        };

        await ParlamentarService.GetElkGeralInterlocutor(wordKey, dataInfoList) // force break
            .then(res => {
                if (res) {
                    setItens(res.data.map(item => {
                        return {
                            id: item.id,
                            nome: item.nome,
                            mandatoTipo: item?.tratamento,
                            partido: item?.sigla_partido,
                            estadoFederacao: item?.uf_exercicio,
                            seguindo: item.cliente ? item.cliente?.seguindo : false,
                            qualificacao: item.cliente ? item.cliente?.qualificacao.toLowerCase() : "não classificado",
                            statusMandato: item?.tratamento === "Senador " || item?.tratamento === undefined ? item?.situacaoReal : item?.situacao,
                            urlImg: item.url_foto ? item.url_foto.replace('http://', 'https://') : undefined,
                            observacao: item.cliente ? item.cliente.observacao : '',
                            id_favoritado: item.id_favoritado,
                            status_favorito: item.status_favorito
                        }
                    }));
                    setInfoList({
                        pageIndex: res.pageIndex,
                        pageItens: res.pageItens,
                        pageSize: res.pageSize,
                        totalItens: res.totalItens,
                        totalPages: res.totalPages
                    });
                    setLoading(false)
                };
            });
    };

    const pressBuscarGeralInterlocutor = async () => {
        setLoading(true)
        const dataInfoList = {
            pageIndex: currentPage,
            pageSize: currentPageSize,
        }
        await ParlamentarService.GetElkGeralInterlocutor(wordKey, dataInfoList) // force break
            .then(res => {
                if (res) {
                    setCurrentPage(1)
                    setItens(res.data.map(item => {
                        return {
                            id: item.id,
                            nome: item.nome,
                            mandatoTipo: item?.tratamento,
                            partido: item?.sigla_partido,
                            estadoFederacao: item?.uf_exercicio,
                            seguindo: item.cliente ? item.cliente?.seguindo : false,
                            qualificacao: item.cliente ? item.cliente?.qualificacao.toLowerCase() : "não classificado",
                            statusMandato: item?.tratamento === "Senador " || item?.tratamento === undefined ? item?.situacaoReal : item?.situacao,
                            urlImg: item.url_foto ? item.url_foto.replace('http://', 'https://') : undefined,
                            observacao: item.cliente ? item.cliente.observacao : '',
                            id_favoritado: item.id_favoritado,
                            status_favorito: item.status_favorito
                        }
                    }));
                    setInfoList({
                        pageIndex: res.pageIndex,
                        pageItens: res.pageItens,
                        pageSize: res.pageSize,
                        totalItens: res.totalItens,
                        totalPages: res.totalPages
                    });
                    setLoading(false)
                }
            });
    }


    // ----------- DISCURSO ------------------//
    // ----------- DISCURSO ------------------//
    // ----------- DISCURSO ------------------// 


    const loadListDiscurso = async (origin?: String) => {
        // if (!origin) {
        setLoading(true)
        // }

        const dataInfoList = {
            pageIndex: currentPage,
            pageSize: currentPageSize,
        }
        await ParlamentarService.GetElkGeralDiscurso(wordKey, dataInfoList) // force break
            .then(res => {
                console.log("res do discurso",res)
                if (res) {
                    setItens(res?.data);
                    setInfoList(res?.listInfo);
                    setLoading(false)
                }
            });
    }

    const pressBuscarGeralDiscurso = async () => {
        setLoading(true)
        const dataInfoList = {

                pageIndex: currentPage - 1,
                 
 
            pageSize: currentPageSize,
        }
        await ParlamentarService.GetElkGeralDiscurso(wordKey, dataInfoList) // force break
            .then(res => {
                if (res) {
                    
                    setCurrentPage(1)
                    setItens(res?.data)
                    setInfoList(res?.listInfo);
                    setLoading(false)
                }
            });
    }

    // ----------- NOTICIAS ------------------//
    // ----------- NOTICIAS ------------------//
    // ----------- NOTICIAS ------------------// 


    const loadListNews = async () => {
        setLoading(true)

        const data = {
            filter: [],
            wordkey: wordKey,
            pageIndex: currentPage,
            pageSize: 100
        };

        await NewsService.GetNewsList(data).then((res) => {
            if (res) {
                setItens(res.data)
                setLoading(false)

            };
        })
    };

    const pressBuscarGeralNews = async () => {
        setLoading(true)
        const data = {
            filter: [],
            wordkey: wordKey,
            pageIndex: currentPage,
            pageSize: 100
        }
        await NewsService.GetNewsList(data).then((res) => {
            if (res) {
                setItens(res.data)
                setLoading(false)

            }
        })
    }

    // ----------- TWITTER ------------------//
    // ----------- TWITTER ------------------//
    // ----------- TWITTER ------------------// 

    const loadListTwitter = async (origin?: String) => {
        if (!origin) {
            setLoading(true)
        }
        const data = {
            pageIndex: currentPage - 1,
            pageSize: currentPageSize,
        }
        await TwitterService.GetTwitterBuscaGeral(wordKey, data).then((res) => {
            if (res) {
                setInfoList(res.data.listInfo)
                setItens(res.data.data)
                setLoading(false)
            }
        });
    };

    const pressBuscarGeralTwitter = async () => {
        setLoading(true)
        const data = {
            pageIndex: currentPage - 1,
            pageSize: currentPageSize,
        }
        await TwitterService.GetTwitterBuscaGeral(wordKey, data).then((res) => {
            if (res) {
                setCurrentPage(1)
                setInfoList(res?.data?.listInfo)
                setItens(res.data.data)
                setLoading(false)
            }
        });
    };


    const favoriteNews = async (x, event: React.ChangeEvent<HTMLInputElement>) => {
        // setStarLoading(true)

        if (x.se_favoritado === false) {
            //if (event && event.target.checked === false) {

            const data = {
                autor: (x.author == null || x.author == undefined ? 'Desconhecido' : x.author),
                conteudo: (x.content == null || x.content == undefined ? '' : x.content),
                descricao: (x.description == null || x.description == undefined ? '' : x.description),
                datapublicado: x.publishedAt,
                veiculo: x.source.name,
                titulo: (x.title == null || x.title == undefined ? 'Desconhecido' : x.title),
                urlnews: x.url,
                urlnewsimg: x.urlToImage
            }

            await NewsService.favorite(data).then((res) => {
                if (res) {
                    NewAlert("success", "O item foi adicionado aos favoritos com sucesso.");
                    loadListNews();
                } else {
                    NewAlert("error", "Erro ao favoritar, tente novamente.");
                }
            })
        } else {

            await NewsService.desfavorite(x.url).then((res) => {
                if (res) {
                    NewAlert("success", "O item foi desfavoritado com sucesso.");
                    loadListNews();
                } else {
                    NewAlert("error", "Erro ao desfavoritar, tente novamente.");
                }
            })
        }
        setStarLoading(false)
    }

    const downloadRoot = async (nome:string, id: number = null, setLoading = (e) => e) => {
        if (id) {
            window.location.href = process.env.REACT_APP_API_URL + "/dou/" + id + "?force-download=true";
            return;
        }
        
        NewAlert('info', `Download do arquivo ${nome} em andamento...`);
        setLoading(true);
        UploadFilesService.download(nome, true)
            .then((resp) => {
                if (resp.status == 200) {
                    const url = window.URL.createObjectURL(new Blob([resp.data]));
                    const link = document.createElement("a");
                    link.href = url;
                    link?.setAttribute("download", nome);
                    document.body.appendChild(link);
                    link?.click();
                    link?.parentNode?.removeChild(link);
                    NewAlert('success', `Download do arquivo ${nome} finalizado com sucesso!`);
                    setLoading(false);
                } else {
                    NewAlert('error', `Erro ao realizar o download do arquivo ${nome}.`);
                    setLoading(false);
                }
            }).catch((err) => {
                NewAlert('error', `Erro ao realizar o download do arquivo ${nome}.`);
                console.error(err);
                setLoading(false);
            });
    }

    // ----------- COMUM ------------------//
    // ----------- COMUM ------------------//
    // ----------- COMUM ------------------// 

    React.useEffect(() => {
        if (tipoAtual === 'PROPOSIÇÕES') {
            loadListProposicao();
            setCurrentPage(1)
        }
        if (tipoAtual === 'INTERLOCUTORES') {
            loadListInterlocutor();
            setCurrentPage(1)
        }
        if (tipoAtual === 'DISCURSOS') {
            loadListDiscurso();
            setCurrentPage(1)
        }
        if (tipoAtual === 'NOTÍCIAS') {
            loadListNews();
            setCurrentPage(1)
        }
        if (tipoAtual === 'TWITTER') {
            loadListTwitter();
            setCurrentPage(1)
        }
        if (tipoAtual === 'DIARIO') {
            loadListDiario();
            setCurrentPage(1)
        }
    }, [tipoAtual]);

    React.useEffect(() => {
        if (tipoAtual === 'PROPOSIÇÕES') {
            loadListProposicao()
        }
        if (tipoAtual === 'INTERLOCUTORES') {
            loadListInterlocutor()
        }
        if (tipoAtual === 'DISCURSOS') {
            loadListDiscurso()
        }
        if (tipoAtual === 'NOTÍCIAS') {
            loadListNews()
        }
        if (tipoAtual === 'TWITTER') {
            loadListTwitter();
        }
        if (tipoAtual === 'DIARIO') {
            pressBuscarGeralDiario()
        }
    }, [currentPage, currentPageSize]);


    React.useEffect(() => {
        if (tipoAtual === 'PROPOSIÇÕES') {
            pressBuscarGeralProposicao()
        }
        if (tipoAtual === 'INTERLOCUTORES') {
            pressBuscarGeralInterlocutor()
        }
        if (tipoAtual === 'DISCURSOS') {
            pressBuscarGeralDiscurso()
        }
        if (tipoAtual === 'NOTÍCIAS') {
            pressBuscarGeralNews()
        }
        if (tipoAtual === 'TWITTER') {
            pressBuscarGeralTwitter()
        }
        if (tipoAtual === 'DIÁRIO') {
            console.log("ENTROU AQUI?!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",tipoAtual)
            pressBuscarGeralDiario()
        }
    }, [wordKey]);


    const onChangeTipo = (event: any) => {
        if (event.target.title === 'PROPOSIÇÕES' || event.target.title === 'INTERLOCUTORES' || event.target.title === 'DISCURSOS' || event.target.title === 'NOTÍCIAS' || event.target.title === 'TWITTER' || event.target.title === 'DIÁRIO') {
            let getType = ''
            if (event.target.title === 'PROPOSIÇÕES') {
                getType = 'PROPOSIÇÕES'
                loadListProposicao()
            } else if (event.target.title === 'NOTÍCIAS') {
                getType = 'NOTÍCIAS'
                loadListTwitter();
            } else if (event.target.title === 'TWITTER') {
                getType = 'TWITTER'
                loadListTwitter();
            } else if (event.target.title === 'DISCURSOS') {
                getType = 'DISCURSOS'
                loadListDiscurso()
            } else if (event.target.title === 'INTERLOCUTORES') {
                getType = 'INTERLOCUTORES'
                loadListInterlocutor()
            } else if (event.target.title === 'DIÁRIO') {
                getType = 'DOU'
                loadListDiario()
            }
            setTipo(
                tipo.map((x) => {
                    if (x.label.toLowerCase() === getType.toLowerCase()) {
                        x.active = true;
                        SetTipoAtual(getType);
                    }
                    else if (x.active) x.active = false;
                    return x;
                })
            );
            localStorage.setItem( 'filter', getType );
        } else {
            setTipo(
                tipo.map((x) => {
                    if (x.label.toLowerCase() === event.target.title.toLowerCase()) {
                        x.active = true;
                        SetTipoAtual(event.target.title === "DIÁRIO" ? "DOU" : event.target.title);
                    }
                    else if (x.active) x.active = false;
                    return x;
                })
            );
            localStorage.setItem( 'filter', event.target.title );
        }
    };

    // funcao para grifar as palavras:
    const search = (e) => {
        if (e) {
            let splitResult = e.split(" ")
            let textJoin = splitResult.map(x => {
                return (
                    <span style={{ backgroundColor: `${x === wordKey || x === wordKey + "." || x === wordKey + "," || x === wordKey + "s" || x === wordKey + "s" + "." || x === capitalize(wordKey) ? 'yellow' : 'white'}` }}>{x + " "}</span>
                )
            })
            return textJoin
        }
    };

    const favoritandoTwitter = async (modulo, id_modulo, status) => {
        const data = {
            modulo: modulo,
            id_modulo: id_modulo,
            tipo: tipoAtual
        };
        if (status === "nao_favorito") {
            setStarLoading(true);
            await FavoritosService.Create(data).then((res) => {
                if (res) {
                    NewAlert("success", "O item foi adicionado aos favoritos com sucesso.");
                    loadListTwitter('fav');
                    setStarLoading(false);
                } else {
                    NewAlert(
                        "error",
                        "Erro ao favoritar, tente novamente."
                    );
                    setStarLoading(false);
                }
            });
        } else {
            setStarLoading(true);
            await FavoritosService.Delete(data, 0, true).then((res) => {
                if (res) {
                    NewAlert("success", "O item foi removido dos favoritos com sucesso.");
                    loadListTwitter('fav');
                    setStarLoading(false);
                } else {
                    NewAlert(
                        "error",
                        "Erro ao Deletar, tente novamente."
                    );
                    setStarLoading(false);
                }
            });



        }

    }

    const favoriteChangeStatus = async (event: any, index: any, id_modulo: number, modulo: string, status_favorito: string, id_favoritado: number) => {
        if (event)
            setStatusFavorite(!statusFavorite[index])

        const data = {
            modulo: modulo,
            id_modulo: id_modulo,
            tipo: tipoAtual
        };

        setData(data);
        if (status_favorito === "nao_favorito") {
            // setStarLoading(true)
            console.log("MODILOLKSDJLKASDJLKJ",data)
            await FavoritosService.Create(data).then((res) => {
                if (res) {
                    NewAlert("success", "O item foi adicionado aos favoritos com sucesso.");

                    if (tipoAtual === 'PROPOSIÇÕES') {
                        loadListProposicao('fav');
                    }
                    if (tipoAtual === 'DISCURSOS') {
                        loadListDiscurso('fav');
                    }
                    if (tipoAtual === 'INTERLOCUTORES') {
                        loadListInterlocutor('fav');
                    }
                    if (tipoAtual === 'TWITTER') {
                        // loadListTwitter();
                    }
                    if (tipoAtual === 'DOU') {
                        loadListDiario('fav');
                    }

                } else {
                    NewAlert(
                        "error",
                        "Erro ao favoritar, tente novamente."
                    );
                }
            });
        } else {
            console.log("ANTES DO SERVICE",tipoAtual)
            await FavoritosService.Delete(id_modulo, id_favoritado, false, tipoAtual).then((res) => {
                if (res) {
                    NewAlert("success", "O item foi removido dos favoritos com sucesso.");

                    if (tipoAtual === 'PROPOSIÇÕES') {
                        loadListProposicao('fav');
                    }
                    if (tipoAtual === 'DISCURSOS') {
                        loadListDiscurso('fav');
                    }
                    if (tipoAtual === 'INTERLOCUTORES') {
                        loadListInterlocutor('fav');
                    }
                    if (tipoAtual === 'TWITTER') {
                        // loadListTwitter();
                    }
                    if (tipoAtual === 'DOU') {
                        loadListDiario('fav');
                    }

                } else {
                    NewAlert(
                        "error",
                        "Erro ao favoritar, verifique os campos e salve novamente."
                    );
                }
            });



        }
        setStarLoading(false)
    }

    const handleClickOpenDialog = (event: React.MouseEvent<HTMLElement>, id) => {
        setOpenDialog(id);
        setAnchorEl(event.currentTarget);

    };

    const handleCloseDialog = () => {
        setAnchorEl(null);
        setOpenDialog(undefined)
    };

    const handleOnChangeItensPerPageSize = (data: any) => {
        setCurrentPageSize(Number(data.target.value));
    };

    const openTweetLink = (username, tweetId) => {
        window.open(`https://twitter.com/${username}/status/${tweetId}`, '_blank').focus();
    };

    const openInterlocutor = (id_parlamentar) => {
        window.open(`/interlocutor/${id_parlamentar}`, '_blank').focus();
    }

    

    return (
        <div className={classes.root}>
            <Main>
                <>
                    <Grid maxWidth={"100%"} container>
                        <Grid item xs={12} key="TitleText">
                            <TitleText pallet={pallet}>Resultado de Busca</TitleText>
                        </Grid>

                        {wordKey === 'Erro: Busca Incorreta' ?
                            <PaperContent pallet={pallet} style={{ marginTop: '40px' }}>
                                <EmptyError title="Ops! Nenhum resultado." description="Que tal refazer a busca?" />
                            </PaperContent>
                            :
                            null
                        }

                        {wordKey !== 'Erro: Busca Incorreta' ?
                            <Paper
                                style={{ borderRadius: "7px", width: "100%", marginTop: "35px" }}
                            >
                                {
                                    !isMobile ?

                                        <Grid
                                            style={{
                                                borderRadius: "7px",
                                                padding: "25px",
                                                backgroundColor: `${pallet.backGroundPageColorPrimary}`,
                                            }}
                                        >
                                            <TiposList filtros={tipo} onChange={onChangeTipo} />
                                        </Grid>
                                        :
                                        // <Grid container flex-direction={'column'} textAlign={'center'} display={'inline-grid'}
                                        //     style={{
                                        //         borderRadius: "7px",
                                        //         padding: "25px",
                                        //         backgroundColor: `${pallet.backGroundPageColorPrimary}`,
                                        //     }}
                                        // >
                                        <Grid container 
                                            style={{
                                                display: "flex",
                                                flexDirection:"column",
                                                justifyContent:"center",
                                                borderRadius: "7px",
                                                padding: "25px",
                                                backgroundColor: `${pallet.backGroundPageColorPrimary}`,
                                            }}
                                        >
                                            <TiposList filtros={tipo} onChange={onChangeTipo} />
                                        </Grid>

                                }
                                <Grid>
                                    <Divider
                                        variant="fullWidth"
                                        style={{
                                            marginBottom: `${isMobile ? "15px" : "30px"}`,
                                        }}
                                    />
                                </Grid>
                                <Grid style={{ marginBottom: "25px" }}>
                                    <Grid container >
                                        {
                                            wordKey !== '---' ?


                                            <Grid item key="TitleText" style={{ paddingLeft: '25px', marginTop: "6px" }}>
                                            <Grid container>
                                                <Grid style={{ fontFamily: pallet.general.fontFamily }}>
                                                    Buscando por:
                                                </Grid>
                                                <Grid style={{ marginLeft: '-20px', marginTop: '-3px' }}>
                                                    <TitleTextResult pallet={pallet}>{wordKey ? wordKey.replace("-", "/") : wordKey}</TitleTextResult>
                                                </Grid>
                                            </Grid>
                                        </Grid>


                                            :

                                            null

                                        }



                                        {tipoAtual === 'NOTÍCIAS'
                                            ?
                                            null
                                            :
                                            <Grid item style={{ paddingLeft: '40px' }}>
                                                <FilterQuantity
                                                    list={infoList}
                                                    currentPageSize={currentPageSize}
                                                    onChange={handleOnChangeItensPerPageSize}
                                                />
                                            </Grid>
                                        }
                                    </Grid>

                                    <Root>
                                        <Divider style={{ marginTop: '20px' }}>
                                            <p style={{ backgroundColor: "white", color: `${pallet.general.color1}`, fontSize: "14px", fontWeight: pallet.general.weight_600 }}>
                                                {tipoAtual === 'NOTÍCIAS'
                                                    ?
                                                    <Grid>
                                                        {itens ? itens.length : 0} {itens ? itens.length > 1 ? "Resultados" : "Resultado" : null}
                                                    </Grid>
                                                    :
                                                    <Grid>
                                                        {infoList ? infoList.totalItens : 0} {infoList ? infoList.totalItens > 1 ? "Resultados" : "Resultado" : null}
                                                    </Grid>}
                                            </p>
                                        </Divider>
                                    </Root>

                                    {/* // ----------------- ESPACO PROPOSICAO -------------------------// */}

                                    {tipoAtual === 'PROPOSIÇÕES' ?
                                        loading ?
                                            <CircularLoading
                                                positionCustom={true}
                                                size={100}
                                                top={isMobile ? "37%" : "50%"}
                                                left={isMobile ? "6%" : "45%"}
                                                zIndex={2}
                                            />
                                            :
                                            itens ? itens.map((xa, index) => {
                                                return (
                                                    <div className={"elasticScore_" + xa.score} id="content">
                                                        <Grid style={{
                                                            paddingLeft: "40px", paddingRight: "40px", paddingTop: "30px",
                                                            fontFamily: `${pallet.general.fontFamily}`
                                                        }}>
                                                            <Grid container>
                                                                <div key={index} style={{ cursor: "pointer", marginTop: "-9px", marginLeft: "-8px" }} onClick={(e) => user.id_permissao !== 3 ? favoriteChangeStatus(e, index, xa.proposicao_id, "proposicao", xa.status_favorito, xa.id) : NewAlert("error", "Não é possível realizar está ação com este perfil")}>
                                                                    {user.id_permissao !== 3
                                                                        ?
                                                                        starLoading
                                                                            ?
                                                                            <CircularLoading
                                                                                positionCustom={true}
                                                                                size={42}
                                                                                top={isMobile ? "37%" : "100%"}
                                                                                left={isMobile ? "6%" : "100%"}
                                                                                zIndex={2}
                                                                            />
                                                                            :
                                                                            <Checkbox
                                                                                {...label}
                                                                                icon={xa.status_favorito === "nao_favorito" ? <StarBorderIcon /> : <StarIcon />}
                                                                                checkedIcon={<StarIcon />}
                                                                                style={{ color: `${pallet.general.color2}` }}
                                                                            />
                                                                        :
                                                                        <Checkbox
                                                                            {...label}
                                                                            icon={xa.status_favorito === "nao_favorito" ? <StarBorderIcon /> : <StarIcon />}
                                                                            checkedIcon={xa.status_favorito === "nao_favorito" ? <StarBorderIcon /> : <StarIcon />}
                                                                            style={{ color: `${pallet.general.color2}` }}
                                                                        />
                                                                    }
                                                                </div>                                   
                                                                <Grid>
                                                                    <a style={{textDecoration:"none",color:"black"}} href={`/proposicao/${xa.proposicao_id}`}>{xa.proposicao_descricao} | {xa.proposicao_casas_nome}</a>
                                                                </Grid>
                                                                <Grid container style={{ fontWeight: 900, fontSize: "10px", color: `${pallet.general.color2}`, marginLeft: "33px" }}>
                                                                    <Grid style={{ marginRight: "8px" }}>
                                                                        PROPOSIÇÕES |
                                                                    </Grid>
                                                                    <Grid>
                                                                        {moment(xa.proposicao_data_apresentacao).format('DD/MM/YYYY')}
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                            <Grid style={{ fontSize: "13px" }}>
                                                                <p>
                                                                    {search(xa.proposicao_ementa)}
                                                                </p>
                                                            </Grid>

                                                        </Grid>
                                                    </div>
                                                )
                                            })
                                                :
                                                null
                                        :
                                        null
                                    }

                                    {/* // ----------------- ESPACO INTERLOCUTOR -------------------------// */}

                                    {tipoAtual === 'INTERLOCUTORES' ?
                                        loading ?
                                            <CircularLoading
                                                positionCustom={true}
                                                size={100}
                                                top={isMobile ? "37%" : "50%"}
                                                left={isMobile ? "6%" : "45%"}
                                                zIndex={2}
                                            />
                                            :
                                            <Grid container style={{ justifyContent: isMobile ? 'space-evenly' : '' }}>
                                                {itens && itens.map((item, index) => (
                                                    <ContainerCard key={item.id}>
                                                        <ParlamentarCard
                                                            key={item.id_favoritado}
                                                            onClick={() => loadListInterlocutor('fav')}
                                                            item={item}
                                                            index={index}
                                                            from={'buscaGeral'}
                                                            favoriteChangeStatus={(e) => favoriteChangeStatus(e, index, item.id, "interlocutor", item.status_favorito, item.id_favoritado)}
                                                        />
                                                    </ContainerCard>                                                    
                                                ))}
                                            </Grid>
                                        :
                                        null
                                    }

                                    {/* // ----------------- ESPACO DISCURSOS -------------------------// */}

                                    {tipoAtual === 'DISCURSOS' ?
                                        loading ?
                                            <CircularLoading
                                                positionCustom={true}
                                                size={100}
                                                top={isMobile ? "37%" : "50%"}
                                                left={isMobile ? "6%" : "45%"}
                                                zIndex={2}
                                            />
                                            :
                                            itens ? itens.map((xb, index) => {
                                                return (
                                                    <div className={"elasticScore_" + xb.score} id="content">
                                                        <Grid style={{
                                                            paddingLeft: "40px", paddingRight: "40px", paddingTop: "30px",
                                                            fontFamily: `${pallet.general.fontFamily}`
                                                        }}>
                                                            <Grid container>
                                                                <div key={index} style={{ cursor: "pointer", marginTop: "-9px", marginLeft: "-8px" }} onClick={(e) => favoriteChangeStatus(e, index, xb.parlamentar_discursos_id, "interlocutor-discurso", xb.status_favorito, xb.id)}>
                                                                    {user.id_permissao !== 3
                                                                        ?
                                                                        starLoading
                                                                            ?
                                                                            <CircularLoading
                                                                                positionCustom={true}
                                                                                size={42}
                                                                                top={isMobile ? "37%" : "100%"}
                                                                                left={isMobile ? "6%" : "100%"}
                                                                                zIndex={2}
                                                                            />
                                                                            :
                                                                            <Checkbox
                                                                                {...label}
                                                                                icon={xb.status_favorito === "nao_favorito" ? <StarBorderIcon /> : <StarIcon />}
                                                                                checkedIcon={<StarIcon />}
                                                                                style={{ color: `${pallet.general.color2}` }}
                                                                            />
                                                                        :
                                                                        <Checkbox
                                                                            {...label}
                                                                            icon={xb.status_favorito === "nao_favorito" ? <StarBorderIcon /> : <StarIcon />}
                                                                            checkedIcon={xb.status_favorito === "nao_favorito" ? <StarBorderIcon /> : <StarIcon />}
                                                                            style={{ color: `${pallet.general.color2}` }}
                                                                        />
                                                                    }
                                                                </div>
                                                                <Grid>
                                                                <a style={{textDecoration:"none",color:"black"}} href={`/interlocutor/${xb.parlamentar_id}`}> {xb.parlamentar_nome}</a>
                                                                </Grid>
                                                                <Grid container style={{ fontWeight: 900, fontSize: "10px", color: `${pallet.general.color2}`, marginLeft: "33px" }}>
                                                                    <Grid style={{ marginRight: "8px" }}>
                                                                        DISCURSO |
                                                                    </Grid>
                                                                    <Grid style={{ marginRight: "8px" }}>
                                                                        {xb.parlamentar_tratamento} |
                                                                    </Grid>
                                                                    <Grid style={{ marginRight: "8px" }}>
                                                                        {xb.parlamentar_sigla_partido} |
                                                                    </Grid>
                                                                    <Grid>
                                                                        {xb.parlamentar_discursos_descricao}
                                                                    </Grid>
                                                                </Grid>
                                                                <Grid style={{ fontWeight: 900, fontSize: "10px", color: `${pallet.general.color2}`, marginLeft: "33px", marginTop: "5px" }}>
                                                                    {xb.parlamentar_discursos_atualizado_em}
                                                                </Grid>
                                                            </Grid>
                                                            {xb.parlamentar_tratamento === "Senador " || xb.parlamentar_tratamento === "Senadora "
                                                                ?
                                                                null
                                                                :
                                                                <Grid style={{ fontSize: "11px", fontWeight: 900, marginTop: "15px" }}>
                                                                    Sumário
                                                                </Grid>
                                                            }
                                                            <Grid style={{ fontSize: "11px", marginTop: '-10px' }}>
                                                                <p>
                                                                    {search(xb.parlamentar_discursos_sumario)}
                                                                </p>
                                                            </Grid>
                                                            <Grid style={{ fontSize: "11px", fontWeight: 900, marginTop: xb.parlamentar_tratamento === "Senador " || xb.parlamentar_tratamento === "Senadora " ? "25px" : "15px" }}>
                                                                Trecho da Transcrição
                                                            </Grid>
                                                            {xb.parlamentar_tratamento === "Senador " || xb.parlamentar_tratamento === "Senadora "
                                                                ?
                                                                <Grid style={{ fontSize: "11px", marginTop: '0px' }}>
                                                                    <a href={xb.parlamentar_discursos_url}>
                                                                        {xb.parlamentar_discursos_url}
                                                                    </a>
                                                                </Grid>
                                                                :
                                                                <Grid style={{ fontSize: "11px", marginTop: '-10px' }}>

                                                                    <Link onClick={(e) => handleClickOpenDialog(e, xb.parlamentar_id)} to={""}>
                                                                        <Grid
                                                                            style={{ fontSize: "11px", marginTop: '10px' }}
                                                                        >
                                                                            {xb.parlamentar_discursos_transcricao ? xb.parlamentar_discursos_transcricao.substring(0, 200) : null}{"..."}
                                                                        </Grid>
                                                                    </Link>
                                                                    <BootstrapDialog style={{ paddingLeft: `${isMobile ? "0px" : "15px"}` }}
                                                                        maxWidth={"md"}
                                                                        onClose={handleCloseDialog}
                                                                        aria-labelledby="customized-dialog-title"
                                                                        open={openDialog === xb.parlamentar_id}
                                                                    >
                                                                        <BootstrapDialogTitle id="customized-dialog-title" onClose={handleCloseDialog}>
                                                                            {xb.parlamentar_nome}{" - "}{xb.parlamentar_discursos_descricao}
                                                                        </BootstrapDialogTitle>

                                                                        <Grid style={{ padding: "30px" }}>
                                                                            {xb.parlamentar_discursos_transcricao}
                                                                        </Grid>
                                                                    </BootstrapDialog>
                                                                </Grid>
                                                            }
                                                        </Grid>
                                                    </div>
                                                )
                                            })
                                                :
                                                null
                                        :
                                        null
                                    }

                                    {/* // ----------------- ESPACO NEWS -------------------------// */}
                                    {tipoAtual === 'NOTÍCIAS' ?
                                        loading ?
                                            <CircularLoading
                                                positionCustom={true}
                                                size={100}
                                                top={isMobile ? "37%" : "50%"}
                                                left={isMobile ? "6%" : "45%"}
                                                zIndex={2}
                                            />
                                            :
                                            itens ? itens.map((xc, index) => {
                                                let urlToImage = String(xc.urlToImage)
                                                let spliturltoimage = urlToImage.split(".")
                                                return (
                                                    <Geral>
                                                    <div id="content">
                                                        <Grid style={{
                                                            paddingLeft: "40px", paddingRight: "40px", paddingTop: "30px",
                                                            fontFamily: `${pallet.general.fontFamily}`
                                                        }}>
                                                            <Grid container justifyContent={'space-between'} style={{ padding: "10px", backgroundColor: `${pallet.backGroundPageColorPrimary}`, borderRadius: '7px' }}>
                                                                <Grid>
                                                                    {starLoading
                                                                        ?
                                                                        <CircularLoading
                                                                            positionCustom={true}
                                                                            size={42}
                                                                            top={isMobile ? "37%" : "100%"}
                                                                            left={isMobile ? "6%" : "100%"}
                                                                            zIndex={2}
                                                                        />
                                                                        :
                                                                        <div key={index} style={{ cursor: "pointer", marginTop: "0px", marginLeft: "0px", marginRight: '-30px' }}>
                                                                            <Checkbox
                                                                                onChange={(e) => favoriteNews(xc, e)}
                                                                                {...label}
                                                                                icon={xc ? xc.se_favoritado ? <StarIcon /> : <StarBorderIcon />
                                                                                    :
                                                                                    <CircularLoading
                                                                                        positionCustom={true}
                                                                                        size={20}
                                                                                        top={isMobile ? "37%" : "50%"}
                                                                                        left={isMobile ? "6%" : "45%"}
                                                                                        zIndex={2}
                                                                                    />
                                                                                }
                                                                                checkedIcon={<StarIcon />}
                                                                                style={{ color: `${pallet.general.color2}` }}
                                                                            />
                                                                        </div>
                                                                    }
                                                                </Grid>
                                                                <Grid item style={{ maxWidth: '450px' }}>
                                                                    <Grid container>
                                                                        {xc.author
                                                                            ?
                                                                            <Grid>
                                                                                {xc.author}
                                                                            </Grid>
                                                                            :
                                                                            <Grid style={{ fontStyle: 'italic', color: "lightgray" }}>
                                                                                Desconhecido
                                                                            </Grid>
                                                                        }

                                                                        <Grid style={{ paddingLeft: "5px", paddingRight: "5px" }}>
                                                                            |
                                                                        </Grid>
                                                                        <Grid>
                                                                            {moment(xc.publishedAt).format('DD/MM/YYYY [às] HH:mm')}
                                                                        </Grid>
                                                                    </Grid>
                                                                    <Grid style={{ fontWeight: 900, width: "450px" }}>
                                                                        {xc.title}
                                                                    </Grid>
                                                                    <Grid style={{ marginTop: "10px", fontWeight: 100, width: "450px" }}>
                                                                        {xc.description}
                                                                    </Grid>
                                                                    <Grid style={{ marginTop: "10px", fontWeight: 100, width: "450px" }}>
                                                                        <a href={xc.url} target="blank">Continue lendo!</a>
                                                                    </Grid>
                                                                </Grid>
                                                                <Grid item>
                                                                    {/* <div style={{ width: "500px", height: "250px", backgroundImage: `url(${spliturltoimage[spliturltoimage.length - 1] === 'jpg' ? xc.urlToImage : imgBackgroundImage})`, backgroundSize: "cover" }} /> */}
                                                                    <div style={{ width: "500px", height: "250px", backgroundImage: `url(${xc.urlToImage})`, backgroundSize: "cover" }} />
                                                                </Grid>
                                                            </Grid>
                                                            <Grid >
                                                            </Grid>
                                                        </Grid>
                                                    </div>
                                                    </Geral>
                                                )
                                            })
                                                :
                                                null
                                        :
                                        null
                                    }



                                    {/* // ----------------- ESPACO TWITTER -------------------------// */}
                                    {tipoAtual === 'TWITTER' ?
                                        loading ?
                                            <CircularLoading
                                                positionCustom={true}
                                                size={100}
                                                top={isMobile ? "37%" : "50%"}
                                                left={isMobile ? "6%" : "45%"}
                                                zIndex={2}
                                            />
                                            :
                                            itens ? itens.map((xc, index) => {
                                                return (
                                                    <Grid style={{ marginBottom: "10px", marginLeft: `${isMobile ? "0px" : "200px"}`, marginRight: `${isMobile ? "0px" : "200px"}`, }}>
                                                        <Accordion expanded elevation={0} style={{ border: "1px solid #EAEAEA" }} >
                                                            <AccordionDetails style={{
                                                                fontFamily: `${pallet.general.fontFamily}`
                                                            }}>
                                                                <Grid style={{ padding: `${isMobile ? "20px 5px 10px 5px" : "10px 15px 0px 5px"}`, width: "100%" }}>

                                                                    <Grid xs={12} lg={12} style={{ marginBottom: `${isMobile ? "8px" : "0px"}` }}>

                                                                        <Grid container justifyContent="space-between">
                                                                            <Grid item>
                                                                                <Grid container>
                                                                                    <div key={index} style={{ cursor: "pointer", marginTop: "5px", marginLeft: "-8px" }}
                                                                                        onClick={(e) => user.id_permissao !== 3
                                                                                            ?
                                                                                            (favoritandoTwitter('twitter', xc._source.tweet_id, xc._source.favoritado),
                                                                                                setSelectedIndex(index))
                                                                                            :
                                                                                            NewAlert("error", "Não é possível realizar está ação com este perfil")
                                                                                        }
                                                                                    >

                                                                                        {
                                                                                            selectedIndex === index
                                                                                                ?
                                                                                                starLoading
                                                                                                    ?
                                                                                                    <CircularLoading
                                                                                                        positionCustom={true}
                                                                                                        size={42}
                                                                                                        top={isMobile ? "37%" : "100%"}
                                                                                                        left={isMobile ? "6%" : "100%"}
                                                                                                        zIndex={2}
                                                                                                    />
                                                                                                    :
                                                                                                    user.id_permissao !== 3 ?
                                                                                                        <Checkbox
                                                                                                            {...label}
                                                                                                            icon={xc._source.favoritado === "nao_favorito" ? <StarBorderIcon /> : <StarIcon />}
                                                                                                            checkedIcon={<StarIcon />}
                                                                                                            style={{ color: `${pallet.general.color2}` }}
                                                                                                        />
                                                                                                        :
                                                                                                        <Checkbox
                                                                                                            {...label}
                                                                                                            icon={xc._source.favoritado === "nao_favorito" ? <StarBorderIcon /> : <StarIcon />}
                                                                                                            checkedIcon={xc._source.favoritado === "nao_favorito" ? <StarBorderIcon /> : <StarIcon />}
                                                                                                            style={{ color: `${pallet.general.color2}` }}
                                                                                                        />
                                                                                                :
                                                                                                user.id_permissao !== 3 ?
                                                                                                    <Checkbox
                                                                                                        {...label}
                                                                                                        icon={xc._source.favoritado === "nao_favorito" ? <StarBorderIcon /> : <StarIcon />}
                                                                                                        checkedIcon={<StarIcon />}
                                                                                                        style={{ color: `${pallet.general.color2}` }}
                                                                                                    />
                                                                                                    :
                                                                                                    <Checkbox
                                                                                                        {...label}
                                                                                                        icon={xc._source.favoritado === "nao_favorito" ? <StarBorderIcon /> : <StarIcon />}
                                                                                                        checkedIcon={xc._source.favoritado === "nao_favorito" ? <StarBorderIcon /> : <StarIcon />}
                                                                                                        style={{ color: `${pallet.general.color2}` }}
                                                                                                    />
                                                                                        }
                                                                                    </div>
                                                                                    <Grid onClick={() => openTweetLink(xc._source.username, xc._source.tweet_id)} style={{ cursor: 'pointer', marginRight: '10px', marginTop: '7px' }}>
                                                                                        <img
                                                                                            style={{ width: '40px' }}
                                                                                            src={TwitterIcon}
                                                                                            alt="twitter_button"
                                                                                        />
                                                                                        {/* <TwitterIcon /> */}
                                                                                    </Grid>
                                                                                    <Typography className={classes.heading} style={{
                                                                                        color: `${pallet.color.tertiary.font}`,
                                                                                        fontFamily: `${pallet.general.fontFamily}`,
                                                                                        fontSize: `${pallet.general.size12}`,
                                                                                        fontWeight: pallet.general.weight_600,
                                                                                        marginTop: "4px",
                                                                                        display: "flex",
                                                                                        alignItems: "center"
                                                                                    }}>
                                                                                        {formatDate(new Date(xc._source.postado_em), "dd/MM/yyyy HH:mm")}
                                                                                    </Typography>
                                                                                </Grid>
                                                                            </Grid>
                                                                            <Grid item style={{ marginTop: '13px', cursor: 'pointer' }} onClick={() => openInterlocutor(xc._source.id_parlamentar)}>
                                                                                <Grid style={{ fontSize: '13px', fontWeight: 900 }} >
                                                                                    {xc._source.tratamento ? xc._source.tratamento : null} {xc._source.nome_parlamentar ? xc._source.nome_parlamentar : null}
                                                                                </Grid>
                                                                                <Grid style={{ fontSize: '11px' }}>
                                                                                    {xc._source.username}
                                                                                </Grid>
                                                                            </Grid>
                                                                        </Grid>
                                                                    </Grid>
                                                                    <Grid xs={12} lg={12} style={{ marginBottom: `${isMobile ? "8px" : "10px"}`, fontSize: '12px' }}>
                                                                        <Grid style={{ marginTop: "10px" }}>
                                                                            {search(xc._source.text)}
                                                                        </Grid>
                                                                    </Grid>
                                                                    {xc._source.url ?
                                                                        <Grid style={{ textAlign: 'center' }}>
                                                                            <img style={{ maxWidth: `${isMobile ? "280px" : "300px"}` }} src={`${xc._source.url}`} alt={'media'} />
                                                                        </Grid>
                                                                        :
                                                                        null}
                                                                </Grid>
                                                            </AccordionDetails>
                                                        </Accordion>
                                                    </Grid >
                                                )
                                            })
                                                :
                                                null
                                        :
                                        null
                                    }

                                      {/* // ----------------- ESPACO DIÁRIO -------------------------// */}

                                      {tipoAtual === 'DOU' ||  tipoAtual === 'DIARIO' ||  tipoAtual === 'DIÁRIO' ?
                                        loading ?
                                            <CircularLoading
                                                positionCustom={true}
                                                size={100}
                                                top={isMobile ? "37%" : "50%"}
                                                left={isMobile ? "6%" : "45%"}
                                                zIndex={2}
                                            />
                                            :
                                            itens ? itens.map((xa, index, {length}) => {
                                                const lastIndex = length - 1;
                                                return (
                                                    <div className={"elasticScore_" + xa.score} id="content">
                                                        <Grid style={{
                                                            paddingLeft: "40px", paddingRight: "40px", paddingTop: "30px",
                                                            fontFamily: `${pallet.general.fontFamily}`
                                                        }}>
                                                            <Grid container>
                                                                <div key={index} style={{ cursor: "pointer", marginTop: "-3px", marginLeft: "-8px" }} onClick={(e) => user.id_permissao !== 3 ? favoriteChangeStatus(e, index, xa?._source?.id, "diario", xa?._source?.favoritado, xa.id) : NewAlert("error", "Não é possível realizar está ação com este perfil")}>
                                                                    {user.id_permissao !== 3
                                                                        ?
                                                                        starLoading
                                                                            ?
                                                                            <CircularLoading
                                                                                positionCustom={true}
                                                                                size={42}
                                                                                top={isMobile ? "37%" : "100%"}
                                                                                left={isMobile ? "6%" : "100%"}
                                                                                zIndex={2}
                                                                            />
                                                                            :
                                                                            <Checkbox
                                                                                {...label}
                                                                                icon={xa?._source?.favoritado === "nao_favorito" ? <StarBorderIcon /> : <StarIcon />}
                                                                                checkedIcon={<StarIcon />}
                                                                                style={{ color: `${pallet.general.color2}` }}
                                                                            />
                                                                        :
                                                                        <Checkbox
                                                                            {...label}
                                                                            icon={xa?._source?.favoritado === "nao_favorito" ? <StarBorderIcon /> : <StarIcon />}
                                                                            checkedIcon={xa?._source?.favoritado === "nao_favorito" ? <StarBorderIcon /> : <StarIcon />}
                                                                            style={{ color: `${pallet.general.color2}` }}
                                                                        />
                                                                    }

                                                                </div> 
                                                                <Grid style={{ cursor: "pointer",   display: "flex",flexDirection: "row",justifyContent: "center",alignItems: "center"}} onClick={() => {downloadRoot(xa?._source?.arquivoDestino, xa?._source?.id, setLoading);}}>
                                                                <div>  SEÇÃO {xa._source?.secao}  {xa._source?.edicaoExtra ? "| EDIÇÃO " + xa._source?.edicaoExtra : null  }</div><div style={{marginTop: "5px"}}><DownloadIcon  style={{ color: `${pallet.general.color2}` }} /></div>
                                                                </Grid>
                                                                <Grid container style={{ fontWeight: 900, fontSize: "10px", color: `${pallet.general.color2}`, marginLeft: "33px" }}>
                                                                    <Grid style={{ marginRight: "8px" }}>
                                                                        Publicado em 
                                                                    </Grid>
                                                                    <Grid>
                                                                        {moment(xa?._source?.publicadoEm).format('DD/MM/YYYY')}
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                            <Grid style={{ fontSize: "13px" }}>
                                                                <p>
                                                                    {search(xa?._source?.resumo)  } 
                                                                </p>
                                                            </Grid>
                                                        </Grid>

                                                        <Grid xl={12} container justifyContent="space-between" style={{ paddingLeft: "40px", paddingRight: "40px"}}>
                                                            <Grid xs={12} lg={12} style={{ marginBottom: `${isMobile ? "8px" : "0px"}` }}>
                                                                <Grid container alignItems="center" style={{
                                                                    fontWeight: pallet.fontWeight.subtitle,
                                                                    fontSize: `${pallet.general.size12}`,
                                                                    color: `${pallet.color.secundary.font}`
                                                                }}>
                                                                    <ArrowRightAltIcon style={{ marginRight: "5px" }}></ArrowRightAltIcon>                                                
                                                                    {<BuscaGeralDiarioModal id={xa?._source?.id}/>}
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    </div>
                                                )
                                            })
                                                :
                                                null
                                        :
                                        null
                                    }

                                </Grid>

                            </Paper>
                            :
                            null
                        }
                        {tipoAtual === 'NOTÍCIAS' ?
                            null
                            :
                            <ContainerFooter container>
                                <PaginationSaveState
                                    name={filterName}
                                    pages={infoList?.totalPages}
                                    page={currentPage}
                                    onChange={setCurrentPage}
                                />
                            </ContainerFooter>
                        }
                    </Grid>
                    {/* </div> */}
                </>
            </Main>
        </div >
    );
}
